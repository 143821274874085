
export default {
	name: "CoreBlockButton",
	props: {
		settings: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			isDialogOpen: false,
		};
	},
	computed: {
		buttonLabel() {
			return this.settings?.label || "Button Label";
		},
		buttonUrl() {
			const url = this.settings?.url;
			if (
				typeof url === " string" &&
				url?.includes("https://calendly.com")
			) {
				return null;
			} else if (url === "dialog") {
				return null;
			}

			const newUrl = this.settings?.newWindow
				? this.replaceUrlBasePath(url)
				: url;
			return newUrl || null;
		},
		clickEvent() {
			if (
				typeof this.settings?.url === "string" &&
				this.settings?.url?.includes("https://calendly.com")
			) {
				return this.showPopUp;
			} else if (this.isDialog) {
				return this.toggleDialog;
			}
			return this.settings?.event || this.noEventFound;
		},
		isInternal() {
			const url = this.settings?.url;
			return (
				(typeof url === "string" && url?.charAt(0) == "/") ||
				(typeof url === "object" && url !== null)
			);
		},
		isDialog() {
			return this.settings?.url === "dialog";
		},
		newWindowTarget() {
			if (this.settings?.newWindow) return "_blank";
			return "_self";
		},
		newWindowAriaLabel() {
			if (this.settings.newWindow)
				return `${this.buttonLabel} (opens in a new window)`;
			return null;
		},
		formSettings() {
			return this.settings?.dialog || {};
		},
	},
	methods: {
		noEventFound() {},
		showPopUp() {
			this.GTMCalendly();
			if (process.client) {
				window.Calendly.showPopupWidget(this.settings?.url);
				this.setCalendlyIframeTitle(this.settings.iframeTitle);
				this.addModalListener();
			}
			return false;
		},
		toggleDialog() {
			this.isDialogOpen = !this.isDialogOpen;
			return false;
		},
		setCalendlyIframeTitle(title) {
			// We capture all the iframes in case DOM has more than the calendly iframe.
			const iframes = document.querySelectorAll("iframe");
			for (const iframe of iframes) {
				if (iframe.src?.includes("https://calendly.com")) {
					iframe.setAttribute(
						"title",
						title || "default-calendly-title"
					);
				}
			}
		},
		closeModalKeyHandler(event) {
			// Key pressed handler.
			if (event.key === "Escape" || event.keyCode === 27) {
				this.closeCalendlyModal();
			}
		},
		closeCalendlyModal() {
			// Close the modal and remove the listener
			window.Calendly.closePopupWidget();
			document.removeEventListener(
				"keydown",
				this.closeModalKeyHandler,
				true
			);
		},
		addModalListener() {
			// Creates the listener
			document.addEventListener(
				"keydown",
				this.closeModalKeyHandler,
				true
			);
		},
		GTMCalendly() {
			this.$gtm.push({
				event: "nyl-calendly",
				calendly_id: this.settings.calendlyId,
				calendly_category: this.getCalendlyCategory(
					this.settings.eventType
				),
				event_type: this.settings.eventType,
				calendly_event_id: this.customEvent,
				click_url: this.settings.url,
			});
		},
		getCalendlyCategory(eventType) {
			var calendlyCategory = "";
			switch (eventType) {
				case "Custom Event":
					calendlyCategory = "custom";
					break;
				case "General Discussion":
				case "Intro Appointment":
				case "Review":
					calendlyCategory = "agent";
					break;
				case "Intro to NYL":
				case "Quick Checkin":
				case "First Interview":
					calendlyCategory = "GO";
					break;
			}
			return calendlyCategory;
		},
		replaceUrlBasePath(url) {
			if (!url || !this.isInternal) return url;

			const basePath = this?.$config?.["_app"]?.basePath ?? "/";
			// replaces consecutive forward slashes with a single one
			return `${basePath}/${url}`.replace(/\/+/g, "/");
		},
	},
};
